import { gql } from "@apollo/client";
import { useLayout } from "@contexts/LayoutProvider";
import { Button, Container, Icon, Link, Tooltip } from "@shared";
import { SessionStudentEvaluationTable } from "@shared/AttendanceGrades/components/SessionStudentEvaluationTable";
import { CohortInfoLine } from "@shared/CohortInfoLine";
import { EmptyStateContainer } from "@shared/EmptyStateContainer";
import {
  getSessionButtonStyles,
  SessionReportModal,
} from "@shared/SessionReports";
import { getOrigin } from "@utils/browser";
import { getHostShortMeetingLink } from "@utils/roomUrls";
import { darkButtonStyles } from "@utils/styleStrings";
import clsx from "clsx";
import { CohortDetailsOpenIcon } from "components/cohorts/CohortDetailsModal/components/CohortDetailsOpenIcon";
import { useEffect, useMemo, useRef, useState } from "react";
import { EventTimeDetails } from "sections/UserDashboard/components/EventTimeDetails";
import { EventScheduleStatus } from "types/events";
import { EventDetails } from "../types";
import { getAttendedCount, getStudentEvaluationDetails } from "../utils";

TutorTabCohortEventPanel.fragments = {
  tutorDashboardEvent: gql`
    fragment TutoringTabCohortEventPanel_TutorDashboardEvent on TutorDashboardEvent {
      subject
      cacheKey
      cohortId
      subSubject
      endDateTime
      engagementId
      startDateTime
      durationMinutes
      mentorTeacherName
      cohortSession {
        id
        sessionReportId
        sessionReportStudentPerformanceCount
      }
      ...SessionStudentEvaluationTable_TutorDashboardEvent
    }
    ${SessionStudentEvaluationTable.fragments.tutorDashboardEvent}
  `,
  tutorDashboardCohort: gql`
    fragment TutoringTabCohortEventPanel_TutorDashboardCohort on TutorDashboardCohort {
      id
      name
      instructionLevel
      publicReferenceId
      activeStudentsCount
      ...SessionStudentEvaluationTable_TutorDashboardCohort
    }
    ${SessionStudentEvaluationTable.fragments.tutorDashboardCohort}
  `,
  tutorDashboardEngagement: gql`
    fragment TutoringTabCohortEventPanel_TutorDashboardEngagement on TutorDashboardEngagement {
      id
      name
      productType
      videoProvider
      ...SessionStudentEvaluationTable_TutorDashboardEngagement
    }
    ${SessionStudentEvaluationTable.fragments.tutorDashboardEngagement}
  `,
};

type Props = { eventDetails: EventDetails };

export function TutorTabCohortEventPanel({ eventDetails }: Props) {
  const { isLessThanXlScreen } = useLayout();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isSessionOver, setIsSessionOver] = useState(false);
  const [showSessionModal, setShowSessionModal] = useState(false);

  const {
    cohort,
    subject,
    subSubject,
    engagement,
    endDateTime,
    cohortSession,
    cohortSessionId,
    mentorTeacherName,
  } = eventDetails;

  const { studentEvaluationRows, evaluationDetails } = useMemo(() => {
    return getStudentEvaluationDetails(eventDetails);
  }, [eventDetails]);

  const { studentAttendanceEntries } = cohortSession || {};
  const { sessionReportId, sessionReportStudentPerformanceCount } =
    cohortSession ?? {};

  useEffect(() => {
    const checkIfSessionOver = () => {
      const now = Date.now();
      if (endDateTime <= now) return setIsSessionOver(true);
      const timeUntilEnd = endDateTime - now;
      timeoutRef.current = setTimeout(() => {
        setIsSessionOver(true);
      }, timeUntilEnd + 1);
    };

    checkIfSessionOver();

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [endDateTime]);

  const reportPerformanceComplete = useMemo(() => {
    const attendedCount = getAttendedCount(studentAttendanceEntries ?? []);
    const sessionCount = sessionReportStudentPerformanceCount ?? 0;
    return attendedCount <= sessionCount;
  }, [sessionReportStudentPerformanceCount, studentAttendanceEntries]);

  if (!engagement || !cohort) return null;

  const cohortHasActiveStudents = cohort.activeStudentsCount > 0;
  const hasAttendanceRecords = (studentAttendanceEntries ?? []).length > 0;
  const reportComplete = !!sessionReportId && reportPerformanceComplete;

  return (
    <Container
      key={eventDetails.cacheKey}
      className="flex flex-col gap-2 w-full overflow-visible z-auto"
    >
      <EventTimeDetails
        endTime={eventDetails.endDateTime}
        status={eventDetails.scheduleStatus}
        startTime={eventDetails.startDateTime}
        minutesElapsed={eventDetails.minutesElapsed}
        conclusion="This cohort session is now over."
        durationMinutes={eventDetails.durationMinutes}
        minutesRemaining={eventDetails.minutesRemaining}
      />
      <div
        className={clsx(
          "flex flex-col lg:flex-row lg:justify-between lg:items-center",
          "relative overflow-visible gap-2"
        )}
      >
        <CohortInfoLine
          cohortId={cohort?.id}
          cohortName={cohort?.name}
          engagementId={engagement?.id}
          engagementName={engagement?.name}
          mentorTeacherName={mentorTeacherName}
          productType={engagement?.productType}
          subjectCombos={[{ subject, subSubject }]}
          instructionLevel={cohort?.instructionLevel}
        />

        <div className="flex items-center gap-x-2 mb-1 w-full lg:w-fit justify-end">
          {cohortHasActiveStudents && hasAttendanceRecords && isSessionOver && (
            <Tooltip
              disabled={!isLessThanXlScreen}
              content="Submit Session Report"
            >
              <Button
                height="xs"
                className={clsx(
                  "leading-3 gap-x-2",
                  darkButtonStyles,
                  getSessionButtonStyles(reportComplete)
                )}
                onClick={() => setShowSessionModal(true)}
              >
                <Icon
                  size={4}
                  color="text-white"
                  icon={reportComplete ? "eyeOn" : "submit"}
                  className={clsx(isLessThanXlScreen ? "-mx-[6px]" : "-ml-1")}
                />
                {!isLessThanXlScreen ? "Session Report" : null}
              </Button>
            </Tooltip>
          )}

          <Tooltip disabled={!isLessThanXlScreen} content="Join Meeting">
            <Link
              target="_blank"
              href={getHostShortMeetingLink(
                getOrigin(),
                cohort.publicReferenceId,
                engagement.videoProvider
              )}
            >
              <div className="flex flex-center h-[28px] w-fit px-[6px] bg-blue-500 rounded-md">
                <Icon
                  icon="zoom"
                  color="text-white"
                  className={clsx(
                    "w-[16px] h-[16px]",
                    isLessThanXlScreen ? "mx-[3px]" : ""
                  )}
                />
                {!isLessThanXlScreen && (
                  <div className="flex h-full items-center text-white text-sm ml-[6px] mr-[6px]">
                    Join Meeting
                  </div>
                )}
              </div>
            </Link>
          </Tooltip>

          <CohortDetailsOpenIcon className="-ml-1 pr-1" cohortId={cohort.id} />
        </div>
      </div>

      <SessionReportModal
        cohortSessionId={String(cohortSessionId)}
        show={showSessionModal && !!cohortSessionId}
        sessionReportId={sessionReportId ?? null}
        onClose={() => setShowSessionModal(false)}
      />

      {cohortHasActiveStudents ? (
        <SessionStudentEvaluationTable
          evaluationDetails={evaluationDetails}
          studentEvaluationRows={studentEvaluationRows}
        />
      ) : (
        <EmptyStateContainer
          title={
            eventDetails.scheduleStatus === EventScheduleStatus.UPCOMING
              ? "This session hasn't occurred yet."
              : eventDetails.scheduleStatus === EventScheduleStatus.ONGOING
              ? "This session is in progress."
              : "This session has concluded."
          }
          showIcon={false}
          className="gap-1 py-3 px-5"
          subtitle={<p>No students have been assigned to this session.</p>}
        />
      )}
    </Container>
  );
}
