import { Organization } from "@generated/graphql";
import { localStorageUtils } from "@utils/browser";
import { DateTimeRange, getWeekRangeInLocalTimeZone } from "@utils/dateTime";
import { EventColor } from "components/weekCalendar";
import { addDays, isThisWeek, setDay } from "date-fns";
import { getShortReadableDateRangeString } from "helpers/dateText";
import { noop } from "lodash";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AdminDashboardViewMode } from "../../sections/AdminDashboard/types";
import { ADMIN_DASHBOARD_ORG_FAVORITES_STORAGE_KEY } from "./constants";

export type FavoriteOrg = {
  id: string;
  name: string;
  active: boolean;
};

type FavoriteOrgState = {
  enabled: boolean;
  faveOrgs: FavoriteOrg[];
};

export type FilterCount = {
  shown: number;
  total: number;
};

type AdminDashboardContextProps = {
  viewMode: AdminDashboardViewMode;
  setViewMode: (mode: AdminDashboardViewMode) => void;
  selectedDate: Date;
  selectedDateMidweek: Date;
  setSelectedDate: (date: Date) => void;
  selectedDateIsThisWeek: boolean;
  weekRangeLocal: DateTimeRange;
  getWeekRangeString: () => string;
  getWeekDaysRangeString: () => string;

  favoriteOrgs: FavoriteOrg[];
  updateFavoriteOrgs: (orgs: Omit<FavoriteOrg, "active">[]) => void;
  isActiveFavoriteOrg: (orgId: Organization["id"]) => boolean;
  setActiveFavoriteOrg: (orgId: Organization["id"], active: boolean) => void;
  eventColorFavoriteOrgMap: Record<Organization["id"], EventColor>;
  setEventColorFavoriteOrgMap: (
    eventColorMap: Record<Organization["id"], EventColor>
  ) => void;
  favoriteOrgsFilterEnabled: boolean;
  setFavoriteOrgsFilterEnabled: (enabled: boolean) => void;
  favoriteOrgsFilterCount: FilterCount;
  setFavoriteOrgsFilterCount: (filterCount: FilterCount) => void;
};

const initialState: AdminDashboardContextProps = {
  viewMode: AdminDashboardViewMode.Overview,
  setViewMode: noop,
  selectedDate: new Date(),
  selectedDateMidweek: setDay(new Date(), 3),
  setSelectedDate: noop,
  selectedDateIsThisWeek: true,
  weekRangeLocal: getWeekRangeInLocalTimeZone(new Date()),
  getWeekRangeString: () => "",
  getWeekDaysRangeString: () => "",
  favoriteOrgs: [],
  updateFavoriteOrgs: noop,
  setActiveFavoriteOrg: noop,
  eventColorFavoriteOrgMap: {},
  setEventColorFavoriteOrgMap: noop,
  isActiveFavoriteOrg: () => false,
  favoriteOrgsFilterEnabled: false,
  setFavoriteOrgsFilterEnabled: noop,
  favoriteOrgsFilterCount: { shown: -1, total: -1 },
  setFavoriteOrgsFilterCount: noop,
};

export const AdminDashboardContext =
  createContext<AdminDashboardContextProps>(initialState);

type Props = { children: ReactNode };

export const AdminDashboardProvider = ({ children }: Props) => {
  // States
  const [viewMode, setViewMode] = useState(initialState.viewMode);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDateMidweek, setSelectedDateMidweek] = useState(
    initialState.selectedDateMidweek
  );
  const [selectedDateIsThisWeek, setSelectedDateIsThisWeek] = useState(
    initialState.selectedDateIsThisWeek
  );
  const [weekRangeLocal, setWeekRangeLocal] = useState(
    initialState.weekRangeLocal
  );
  const [favoriteOrgsState, setFavoriteOrgsState] = useState<FavoriteOrgState>(
    () => {
      const stored = localStorageUtils.getItem(
        ADMIN_DASHBOARD_ORG_FAVORITES_STORAGE_KEY
      );
      return stored ? JSON.parse(stored) : { enabled: false, faveOrgs: [] };
    }
  );
  const [eventColorFavoriteOrgMap, setEventColorFavoriteOrgMap] = useState<
    Record<Organization["id"], EventColor>
  >({});
  const [favoriteOrgsFilterCount, setFavoriteOrgsFilterCount] =
    useState<FilterCount>({ shown: -1, total: -1 });

  // Callbacks
  const getWeekRangeString = useCallback(
    () =>
      getShortReadableDateRangeString(weekRangeLocal.start, weekRangeLocal.end),
    [weekRangeLocal]
  );

  const handleSetSelectedDate = (date: Date) => {
    setSelectedDate(date);
    setSelectedDateMidweek(setDay(date, 3));
  };

  const getWeekDaysRangeString = useCallback(() => {
    return getShortReadableDateRangeString(
      addDays(weekRangeLocal.start, 1),
      addDays(weekRangeLocal.end, -1)
    );
  }, [weekRangeLocal]);

  const handleSetFavoriteOrgsFilterEnabled = useCallback((enabled: boolean) => {
    setFavoriteOrgsState((prev) => {
      const newState = { ...prev, enabled };
      localStorageUtils.setItem(
        ADMIN_DASHBOARD_ORG_FAVORITES_STORAGE_KEY,
        JSON.stringify(newState)
      );
      return newState;
    });
  }, []);

  const handleUpdateFavoriteOrgs = useCallback(
    (orgs: Omit<FavoriteOrg, "active">[]) => {
      setFavoriteOrgsState((prev) => {
        const newOrgs = orgs.filter(
          ({ id }) => !prev.faveOrgs.some((org) => org.id === id)
        );
        const existingOrgs = prev.faveOrgs.filter((org) =>
          orgs.some(({ id }) => org.id === id)
        );

        const newFavoriteOrgs = [
          ...newOrgs.map((org) => ({ ...org, active: true })),
          ...existingOrgs,
        ];
        newFavoriteOrgs.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );

        const newEnabled =
          newFavoriteOrgs.length === 0
            ? false
            : prev.faveOrgs.length === 0
            ? true
            : prev.enabled;
        const newState = {
          enabled: newEnabled,
          faveOrgs: newFavoriteOrgs,
        };

        localStorageUtils.setItem(
          ADMIN_DASHBOARD_ORG_FAVORITES_STORAGE_KEY,
          JSON.stringify(newState)
        );

        return newState;
      });
    },
    []
  );

  const setActiveFavoriteOrg = useCallback(
    (orgId: Organization["id"], active: boolean) => {
      setFavoriteOrgsState((prev) => {
        const newFavoriteOrgs = prev.faveOrgs.map((org) => {
          if (org.id === orgId) {
            return { ...org, active };
          }
          return org;
        });
        const newState = { ...prev, faveOrgs: newFavoriteOrgs };

        localStorageUtils.setItem(
          ADMIN_DASHBOARD_ORG_FAVORITES_STORAGE_KEY,
          JSON.stringify(newState)
        );
        return newState;
      });
    },
    []
  );

  const isActiveFavoriteOrg = useCallback(
    (orgId: Organization["id"]) =>
      favoriteOrgsState.faveOrgs.find((org) => org.id === orgId)?.active ??
      false,
    [favoriteOrgsState]
  );

  // Effects
  useEffect(() => {
    setSelectedDateIsThisWeek(isThisWeek(selectedDate));
    setWeekRangeLocal(getWeekRangeInLocalTimeZone(selectedDate));
  }, [selectedDate]);

  useEffect(
    () =>
      localStorageUtils.addStorageListener(
        ADMIN_DASHBOARD_ORG_FAVORITES_STORAGE_KEY,
        (newValue) => {
          if (newValue) {
            const stored = JSON.parse(newValue);
            setFavoriteOrgsState(stored);
          } else {
            setFavoriteOrgsState({ enabled: false, faveOrgs: [] });
          }
        }
      ),
    []
  );

  const contextValue = useMemo(
    () => ({
      viewMode,
      setViewMode,
      selectedDate,
      selectedDateMidweek,
      setSelectedDate: handleSetSelectedDate,
      selectedDateIsThisWeek,
      weekRangeLocal,
      getWeekRangeString,
      getWeekDaysRangeString,
      favoriteOrgs: favoriteOrgsState.faveOrgs,
      updateFavoriteOrgs: handleUpdateFavoriteOrgs,
      setActiveFavoriteOrg,
      eventColorFavoriteOrgMap,
      setEventColorFavoriteOrgMap,
      isActiveFavoriteOrg,
      favoriteOrgsFilterEnabled: favoriteOrgsState.enabled,
      setFavoriteOrgsFilterEnabled: handleSetFavoriteOrgsFilterEnabled,
      favoriteOrgsFilterCount,
      setFavoriteOrgsFilterCount,
    }),
    [
      viewMode,
      selectedDate,
      selectedDateMidweek,
      selectedDateIsThisWeek,
      weekRangeLocal,
      getWeekRangeString,
      getWeekDaysRangeString,
      favoriteOrgsState.faveOrgs,
      favoriteOrgsState.enabled,
      handleUpdateFavoriteOrgs,
      setActiveFavoriteOrg,
      eventColorFavoriteOrgMap,
      isActiveFavoriteOrg,
      handleSetFavoriteOrgsFilterEnabled,
      favoriteOrgsFilterCount,
    ]
  );

  return (
    <AdminDashboardContext.Provider value={contextValue}>
      {children}
    </AdminDashboardContext.Provider>
  );
};

export const useAdminDashboardContext = (): AdminDashboardContextProps =>
  useContext(AdminDashboardContext);
