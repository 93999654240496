import { ISODate, normalizeToLocalISODate } from "@utils/dateTime";
import { getInputStyle } from "@utils/styleStrings";
import { DatePicker } from "antd";
import clsx from "clsx";
import dayjs from "dayjs";

type Props = {
  staticWidth?: boolean;
  currentISODayET: ISODate;
  selectedISODayET: ISODate;
  setSelectedISODayET: (date: ISODate) => void;
};

export const DayDatePicker = ({
  currentISODayET,
  selectedISODayET,
  staticWidth = false,
  setSelectedISODayET,
}: Props) => {
  const isCustomDate = selectedISODayET !== currentISODayET;

  return (
    <div
      className={clsx(
        "flex items-center gap-x-3 h-14",
        "px-4 rounded-[12px] shadow-options-outer",
        staticWidth ? "w-[250px]" : "w-full lg:w-[250px]",
        isCustomDate ? "bg-slate-600" : "bg-white"
      )}
    >
      <label
        className={clsx(
          "text-sm font-medium whitespace-nowrap overflow-hidden",
          isCustomDate ? "text-white" : "text-slate-700"
        )}
      >
        {isCustomDate ? "Custom" : "Today"}
      </label>
      <DatePicker
        onChange={(date) => {
          date && setSelectedISODayET(normalizeToLocalISODate(date.toDate()));
        }}
        defaultValue={dayjs(currentISODayET)}
        format={"MM/DD/YYYY"}
        style={getInputStyle}
        className="flex-1"
        showNow={false}
      />
    </div>
  );
};
