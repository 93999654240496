import { NoteStatusIndicator } from "@shared";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";

const CHAR_PX_WIDTH = 8;
const NOTES_PADDING = 50;

type Props = {
  loading: boolean;
  colWidth: number;
  showNote: boolean;
  className?: string;
  attendanceNote: string;
  updateAttendanceNote: (note: string) => void;
};

export const MentorDashAttendanceNote = ({
  loading,
  showNote,
  colWidth,
  className,
  attendanceNote,
  updateAttendanceNote,
}: Props) => {
  const [note, setNote] = useState<string>("");
  const [debouncedNote, { isPending }] = useDebounce(note, 400);
  const [noteUpdateIsLocal, setNoteUpdateIsLocal] = useState(false);

  // Handles internal note updates
  useEffect(() => {
    if (debouncedNote !== attendanceNote && noteUpdateIsLocal)
      updateAttendanceNote(debouncedNote);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedNote]);

  // Handles external note updates (initial load & via subscriptions)
  useEffect(() => {
    if (note === attendanceNote) setNoteUpdateIsLocal(false);
    if (note !== attendanceNote && !noteUpdateIsLocal) setNote(attendanceNote);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendanceNote]);

  const rows = useMemo(() => {
    const noteWidth = note.length * CHAR_PX_WIDTH;
    const noteSpaceWidth = colWidth - NOTES_PADDING;
    return note ? Math.ceil(noteWidth / noteSpaceWidth) : 1;
  }, [note, colWidth]);

  return showNote ? (
    <div className={clsx("relative w-full mt-[6px]", className)}>
      <label htmlFor="note" className="sr-only">
        Add a note
      </label>
      <textarea
        name="note"
        rows={rows}
        value={note}
        placeholder="Add your attendance note..."
        className="flex w-full justify-start border-gray-300 border focus:border-2 items-start focus:border-blue-500 focus:ring-0 sm:text-sm rounded-lg pr-7 min-h-10 h-auto"
        onChange={(event) => {
          setNote(event.target.value);
          setNoteUpdateIsLocal(true);
        }}
      />
      <div className="absolute right-1 bottom-[7px]">
        <NoteStatusIndicator
          debouncePending={isPending()}
          mutationLoading={loading}
        />
      </div>
    </div>
  ) : null;
};
