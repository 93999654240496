import { gql, useQuery } from "@apollo/client";
import {
  EngagementInstructionalSupportAttendanceStatus,
  GetCohortSessionSubstitutesQuery,
  GetCohortSessionSubstitutesQueryVariables,
  SubstituteTeacherSelector_IsAttendanceFragment,
  SubstituteTeacherSelector_IsAttendanceFragmentDoc,
} from "@generated/graphql";
import { SubstituteTeacherSelector } from "components/shared";
import { useMemo, useState } from "react";

const COHORT_SESSION_SUBSTITUTE_OPTIONS = gql`
  query GetCohortSessionSubstitutes($id: ID!) {
    cohortSession(id: $id) {
      id
      availableSubstitutes {
        ...SubstituteTeacherSelector_ISAttendance
      }
    }
  }
  ${SubstituteTeacherSelector_IsAttendanceFragmentDoc}
`;

type Props = {
  show: boolean;
  cohortSessionId?: string | null;
  substituteName?: string | null;
  selectedSubstituteId: string | null;
  setSubstituteId: (substituteId: string | null) => void;
};

export function SubstituteSelectorWrapper({
  show,
  substituteName,
  cohortSessionId,
  selectedSubstituteId,
  setSubstituteId,
}: Props) {
  const [populateMenu, setPopulateMenu] = useState(false);

  const { data, loading } = useQuery<
    GetCohortSessionSubstitutesQuery,
    GetCohortSessionSubstitutesQueryVariables
  >(COHORT_SESSION_SUBSTITUTE_OPTIONS, {
    fetchPolicy: "no-cache",
    skip: !show || !populateMenu,
    variables: { id: cohortSessionId ?? "" },
    onError: (error) => console.error(error),
  });

  const subOptions = useMemo(() => {
    const hasSub = !!selectedSubstituteId && !!substituteName;

    const sub: SubstituteTeacherSelector_IsAttendanceFragment = {
      id: selectedSubstituteId ?? "",
      status: EngagementInstructionalSupportAttendanceStatus.Present,
      user: { id: selectedSubstituteId ?? "", fullName: substituteName ?? "" },
    };

    return data?.cohortSession?.availableSubstitutes ?? (hasSub ? [sub] : []);
  }, [
    data?.cohortSession?.availableSubstitutes,
    selectedSubstituteId,
    substituteName,
  ]);

  if (!show) return null;

  return (
    <SubstituteTeacherSelector
      heightPx={29}
      loading={loading}
      isAttendanceEntries={subOptions}
      selectedSubstituteId={selectedSubstituteId}
      onInitialClick={() => !populateMenu && setPopulateMenu(true)}
      onSelectSubstitute={(subId) =>
        selectedSubstituteId !== subId && setSubstituteId(subId ?? null)
      }
    />
  );
}
