import { useAdminDashboardContext } from "@contexts/AdminDashboardProvider";
import { Icon, ToggleButton, Tooltip } from "@shared";
import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { AdminDashboardOrgFavoritesFilterButton } from "./components/AdminDashboardOrgFavoritesFilterButton";

type Props = {
  onClickEdit(): void;
};

export function AdminDashboardOrgFavoritesFilterTool({ onClickEdit }: Props) {
  const {
    favoriteOrgs,
    favoriteOrgsFilterEnabled,
    setFavoriteOrgsFilterEnabled,
    setActiveFavoriteOrg,
    favoriteOrgsFilterCount,
  } = useAdminDashboardContext();

  return (
    <div className="flex flex-row gap-1 flex-nowrap bg-white rounded-lg px-2 pb-2 overflow-clip">
      <div className="relative flex-1">
        <div
          className={clsx(
            "flex flex-row gap-2 justify-start items-end content-endx flex-wrap",
            "flex-1 pt-8 pr-2",
            "w-full max-h-28x max-h-20 overflow-y-scroll",
            "transition-opacity",
            favoriteOrgsFilterEnabled ? "opacity-100" : "opacity-30",
            getScrollbarStyle("gray")
          )}
        >
          {favoriteOrgs.length === 0 && (
            <p
              className="text-sm text-black italic cursor-pointer"
              onClick={onClickEdit}
            >
              Select your favorite Organizations to get started
            </p>
          )}
          {favoriteOrgs.map((faveOrg) => (
            <AdminDashboardOrgFavoritesFilterButton
              key={faveOrg.id}
              faveOrg={faveOrg}
              onClick={setActiveFavoriteOrg}
            />
          ))}
        </div>
        <p
          className={clsx(
            "absolute text-xs text-gray-500 font-semibold",
            "h-8 pl-2 pt-1 top-0 -left-2 w-full",
            "bg-gradient-to-b from-white via-white/60 to-transparent",
            "pointer-events-none"
          )}
        >
          Favorite Organizations Filter
          {favoriteOrgsFilterCount.shown === -1 ? (
            ""
          ) : (
            <span className="italic">
              {` (Showing ${favoriteOrgsFilterCount.shown} of ${favoriteOrgsFilterCount.total})`}
            </span>
          )}
        </p>
      </div>

      <div className="flex flex-col gap-y-1 justify-end items-center pt-2">
        <Icon
          className="cursor-pointer"
          icon="edit"
          size={7}
          onClick={onClickEdit}
        />
        <Tooltip
          content={`Favorites Filter: ${
            favoriteOrgsFilterEnabled ? "Active" : "Inactive"
          }`}
          disabled={favoriteOrgs.length === 0}
        >
          <ToggleButton
            disabled={favoriteOrgs.length === 0}
            toggleState={favoriteOrgsFilterEnabled}
            handleToggle={setFavoriteOrgsFilterEnabled}
          />
        </Tooltip>
      </div>
    </div>
  );
}
