import { tableTitleTextStyles } from "@utils/styleStrings";
import clsx from "clsx";
import {
  DETAILS_MIN_WIDTH,
  HOST_PRESENCE_WIDTH,
  ROLE_WIDTH,
} from "../constants";

type Props = { attendanceColWidth: number; staffColWidth: number };

export const StaffAttendanceTableHeader = ({
  staffColWidth,
  attendanceColWidth,
}: Props) => (
  <div
    className={clsx(
      "flex items-center w-full h-[36px] bg-gray-50 rounded-t-lg",
      tableTitleTextStyles
    )}
  >
    <div className="flex p-3" style={{ width: `${staffColWidth}px` }}>
      Staff
    </div>

    <div
      className="flex justify-center p-3"
      style={{ width: `${ROLE_WIDTH}px` }}
    >
      Role
    </div>

    <div
      className="flex justify-center p-3"
      style={{ width: `${HOST_PRESENCE_WIDTH}px` }}
    >
      Host Presence
    </div>

    <div className="flex p-3" style={{ width: `${attendanceColWidth}px` }}>
      Attendance
    </div>

    <div
      className="flex flex-1 p-3 w-fit"
      style={{ minWidth: `${DETAILS_MIN_WIDTH}px` }}
    >
      Cohort Session Details
    </div>
  </div>
);
