import { gql, useQuery } from "@apollo/client";
import {
  AdminDashboardOrgFavoritesFilterModalQuery,
  AdminDashboardOrgFavoritesFilterModalQueryVariables,
} from "@generated/graphql";
import { ErrorBox, LoadingSkeleton, Modal } from "@shared";
import { noop } from "lodash";
import { AdminDashboardOrgFavoritesFilterModalBody } from "./AdminDashboardOrgFavoritesFilterModalBody";

const ORGANIZATIONS_QUERY = gql`
  query AdminDashboardOrgFavoritesFilterModal {
    advancedSearchOrganizations(
      filter: {}
      sort: [{ field: NAME, order: ASC }]
      limit: 1000
    ) {
      items {
        ...AdminDashboardOrgFavoritesFilterModalBody_Organization
      }
    }
  }
  ${AdminDashboardOrgFavoritesFilterModalBody.fragments.organization}
`;

type Props = {
  show: boolean;
  closeModal(): void;
  afterLeave?(): void;
};

export function AdminDashboardOrgFavoritesFilterModal({
  show,
  closeModal,
  afterLeave,
}: Props) {
  const { data, loading, error } = useQuery<
    AdminDashboardOrgFavoritesFilterModalQuery,
    AdminDashboardOrgFavoritesFilterModalQueryVariables
  >(ORGANIZATIONS_QUERY, {
    fetchPolicy: "cache-first",
    skip: !show,
  });

  return (
    <Modal
      show={show}
      onClose={noop}
      onDismissClick={closeModal}
      icon={<Modal.Icon icon="organization" />}
      title="Favorite Organizations"
      subtitle="Select the Organizations you wish to see in the Favorite Organizations Filter."
      width="xlarge"
      afterLeave={afterLeave}
    >
      {loading ? (
        <LoadingSkeleton />
      ) : error ? (
        <ErrorBox msg={error.message} />
      ) : (
        data?.advancedSearchOrganizations?.items && (
          <AdminDashboardOrgFavoritesFilterModalBody
            organizations={data.advancedSearchOrganizations.items}
            closeModal={closeModal}
          />
        )
      )}
    </Modal>
  );
}
