import {
  FavoriteOrg,
  useAdminDashboardContext,
} from "@contexts/AdminDashboardProvider";
import clsx from "clsx";
import { DEFAULT_EVENT_COLOR } from "components/weekCalendar";

type Props = {
  faveOrg: FavoriteOrg;
  onClick(orgId: FavoriteOrg["id"], active: boolean): void;
};

export function AdminDashboardOrgFavoritesFilterButton({
  faveOrg,
  onClick,
}: Props) {
  const { eventColorFavoriteOrgMap } = useAdminDashboardContext();
  const eventColor =
    eventColorFavoriteOrgMap[faveOrg.id] ?? DEFAULT_EVENT_COLOR;

  return (
    <div
      className={clsx(
        "relative flex items-center py-1 px-2.5 rounded-full",
        "cursor-pointer select-none border",
        faveOrg.active
          ? clsx("border-transparent", eventColor.bg, eventColor.bgHover)
          : clsx("bg-white", eventColor.border)
      )}
      onClick={() => onClick(faveOrg.id, !faveOrg.active)}
    >
      <span
        className={clsx(
          "text-sm max-w-[12ch] truncate font-semibold",
          faveOrg.active
            ? clsx(eventColor.text, eventColor.textHover)
            : "text-gray-400"
        )}
      >
        {faveOrg.name}
      </span>
    </div>
  );
}
