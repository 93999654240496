import { gql } from "@apollo/client";
import { StudentCountCell_TutorDashboardCohortFragment } from "@generated/graphql";
import { tableHeaderStyle } from "@utils/styleStrings";
import { CellProps, Column } from "react-table";
import { InstructionalSupportTabDashboardEventDetails } from "../types";

studentCountCell.fragments = {
  tutorDashboardCohort: gql`
    fragment StudentCountCell_TutorDashboardCohort on TutorDashboardCohort {
      id
      activeStudentsCount
    }
  `,
};

type EventDetails = InstructionalSupportTabDashboardEventDetails<
  unknown,
  StudentCountCell_TutorDashboardCohortFragment,
  unknown
>;

export function studentCountCell<D extends EventDetails>(): Column<D> {
  return {
    Header: () => <span className={tableHeaderStyle()}>Students</span>,
    width: 100,
    maxWidth: 100,
    accessor: "cohort",
    Cell: ({
      row: {
        original: { cohort },
      },
    }: CellProps<D>) => (
      <p className="flex flex-center">{cohort?.activeStudentsCount ?? 0}</p>
    ),
  };
}
