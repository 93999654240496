import { gql } from "@apollo/client";
import {
  MentorDashAssignmentRole,
  TutoringPreferencesPosition,
} from "@generated/graphql";
import { tableCellStyle } from "@utils/styleStrings";
import { AccountRoleBadge } from "components/shared";
import { ROLE_WIDTH } from "../../constants";

RoleCell.fragments = {
  sessionAssignmentRow: gql`
    fragment RoleCell_SessionAssignmentRow on MentorDashSessionAssignmentRow {
      staffRole
    }
  `,
};

type Props = { staffRole: MentorDashAssignmentRole };

export function RoleCell({ staffRole }: Props) {
  return (
    <div className={tableCellStyle()} style={{ width: `${ROLE_WIDTH}px` }}>
      <AccountRoleBadge
        mode="tiny"
        accountRole={
          staffRole === MentorDashAssignmentRole.Tt
            ? TutoringPreferencesPosition.TeacherTutor
            : TutoringPreferencesPosition.InstructionalSupport
        }
      />
    </div>
  );
}
