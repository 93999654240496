import { gql, useQuery } from "@apollo/client";
import {
  GetCurrentUserQuery,
  MentorDashboardQuery,
  MentorDashboardQueryVariables,
} from "@generated/graphql";
import {
  ISODate,
  MINUTE_MS,
  getDayDateTimeRangeInTimeZone,
  normalizeToZonedISODate,
} from "@utils/dateTime";
import { fetchErrToast } from "@utils/errorLogging";
import { Routes } from "@utils/routes";
import { useInterval } from "@utils/useInterval";
import {
  Container,
  Icon,
  IconText,
  Link,
  ToggleButton,
} from "components/shared";
import { EmptyStateContainer } from "components/shared/EmptyStateContainer";
import { useUserDashboardData } from "contexts/UserDashboardDataProvider";
import { uniq } from "lodash";
import { Fragment, useEffect, useMemo, useState } from "react";
import { DayDatePicker } from "../components/DayDatePicker";
import { EventTimeDetails } from "../components/EventTimeDetails";
import { MentorDashboardEngagementTable } from "./components";
import { MentorTeacherDashboardTeacherAttendanceSubscriptionComponent } from "./mentorTeacherAttendanceSubscription";
import { formatTimeslots, getTimeslotKey } from "./utils";

const GET_MENTOR_DASHBOARD_QUERY = gql`
  query MentorDashboard(
    $userId: ID!
    $showISs: Boolean!
    $dayTimeRange: DateTimeRangeInput!
  ) {
    getMentorDashboard(
      userId: $userId
      showISs: $showISs
      dayTimeRange: $dayTimeRange
    ) {
      id
      endTime
      startTime
      engagements {
        ...MentorDashboardEngagementTable_DashboardEngagement
        sessionAssignmentRows {
          ...MentorDashboardEngagementTable_SessionAssignmentRow
        }
      }
    }
  }
  ${MentorDashboardEngagementTable.fragments.engagement}
  ${MentorDashboardEngagementTable.fragments.sessionAssignmentRow}
`;

type Props = { currentUser: NonNullable<GetCurrentUserQuery["currentUser"]> };

export const MentorDashboard = ({ currentUser }: Props) => {
  const { setShowISs, showISs } = useUserDashboardData();
  const [currentDate, setCurrentDate] = useState(new Date());

  const currentISODayET: ISODate = useMemo(() => {
    return normalizeToZonedISODate(currentDate);
  }, [currentDate]);

  const [selectedISODayET, setSelectedISODayET] =
    useState<ISODate>(currentISODayET);

  const dayInET = useMemo(() => {
    return getDayDateTimeRangeInTimeZone(selectedISODayET);
  }, [selectedISODayET]);

  useInterval(() => setCurrentDate(new Date()), MINUTE_MS);

  const { data, loading, refetch } = useQuery<
    MentorDashboardQuery,
    MentorDashboardQueryVariables
  >(GET_MENTOR_DASHBOARD_QUERY, {
    fetchPolicy: "cache-first",
    variables: {
      showISs: showISs,
      userId: currentUser.id,
      dayTimeRange: { start: +dayInET.start, end: +dayInET.end },
    },
    onError: (err) => fetchErrToast("this Dashboard data.", err),
  });

  const { timeslots, engagementIds } = useMemo(() => {
    if (!data) return { timeslots: [], engagementIds: [] };
    const { getMentorDashboard: timeslots } = data;

    return {
      timeslots: formatTimeslots(timeslots, currentDate),
      engagementIds: uniq(
        timeslots.flatMap((ts) => ts.engagements.map((e) => e.engagementId))
      ),
    };
  }, [data, currentDate]);

  useEffect(() => {
    refetch({
      userId: currentUser.id,
      showISs: showISs,
      dayTimeRange: { start: +dayInET.start, end: +dayInET.end },
    });
  }, [currentUser.id, refetch, showISs, selectedISODayET, dayInET]);

  const renderTimeslots =
    timeslots.length > 0 ? (
      <div className="relative flex flex-col gap-y-4">
        {timeslots.map((timeslot, i) => (
          <Container
            zIndex={timeslots.length - i}
            key={`${timeslot.timeslot.id}`}
            className="flex flex-col gap-2 gap-y-4"
          >
            <EventTimeDetails
              status={timeslot.status}
              endTime={timeslot.endTime}
              startTime={timeslot.startTime}
              durationMinutes={timeslot.duration}
              minutesElapsed={timeslot.minutesElapsed}
              minutesRemaining={timeslot.minutesRemaining}
            />
            {timeslot.timeslot.engagements.map((engagement, i) => (
              <Fragment key={engagement.id}>
                <IconText
                  icon="engagement"
                  className="-mb-2"
                  child={
                    <Link
                      route={Routes.engagementsDashboard}
                      routeProps={[`engagementId=${engagement.engagementId}`]}
                    >
                      {engagement.name}
                    </Link>
                  }
                />
                <MentorDashboardEngagementTable
                  engagement={engagement}
                  rows={engagement.sessionAssignmentRows}
                  zIndex={timeslot.timeslot.engagements.length - i}
                  timeslotString={getTimeslotKey(
                    timeslot.startTime,
                    timeslot.duration
                  )}
                />
              </Fragment>
            ))}
          </Container>
        ))}
      </div>
    ) : (
      <Container>
        <EmptyStateContainer
          loading={loading}
          icon={<Icon icon="cancelCalendar" size={6} />}
          subtitle="You have no active sessions scheduled for today."
          dataName="Active Sessions"
        />
      </Container>
    );

  return (
    <>
      <div className="flex w-full justify-between gap-2 items-center flex-col sm:flex-row">
        <ToggleButton
          toggleState={showISs}
          className="!text-base !font-semibold text-slate-800"
          handleToggle={(newState) => setShowISs(newState)}
          label="Show Instructional Support"
        />

        <DayDatePicker
          staticWidth
          currentISODayET={currentISODayET}
          selectedISODayET={selectedISODayET}
          setSelectedISODayET={setSelectedISODayET}
        />
      </div>

      {renderTimeslots}

      {data && (
        <MentorTeacherDashboardTeacherAttendanceSubscriptionComponent
          engagementIds={engagementIds}
        />
      )}
    </>
  );
};
