import { gql } from "@apollo/client";
import { useLayout } from "@contexts/LayoutProvider";
import {
  StatusCell_TutorDashboardCohortFragment,
  StatusCell_TutorDashboardEngagementFragment,
  StatusCell_TutorDashboardEventFragment,
} from "@generated/graphql";
import { getOrigin } from "@utils/browser";
import { getHostShortMeetingLink } from "@utils/roomUrls";
import { darkButtonStyles } from "@utils/styleStrings";
import clsx from "clsx";
import { Button, Icon, Link, Tooltip } from "components/shared";
import {
  format,
  formatDuration,
  intervalToDuration,
  isToday,
  isTomorrow,
  isYesterday,
} from "date-fns";
import { useEffect, useRef, useState } from "react";
import { CellProps, Column } from "react-table";
import { ShowSessionReportModalProps } from "sections/UserDashboard/types";
import { EventScheduleStatus } from "types/events";
import { getSessionButtonStyles } from "../../../../../../components/shared/SessionReports/SessionReportModal/utils";
import { InstructionalSupportTabDashboardEventDetails } from "../types";

statusCell.fragments = {
  tutorDashboardEvent: gql`
    fragment StatusCell_TutorDashboardEvent on TutorDashboardEvent {
      cacheKey
      endDateTime
      startDateTime
      durationMinutes
      cohortSession {
        id
        sessionReportId
      }
    }
  `,
  tutorDashboardCohort: gql`
    fragment StatusCell_TutorDashboardCohort on TutorDashboardCohort {
      id
      publicReferenceId
    }
  `,
  tutorDashboardEngagement: gql`
    fragment StatusCell_TutorDashboardEngagement on TutorDashboardEngagement {
      id
      videoProvider
    }
  `,
};

type EventDetails = InstructionalSupportTabDashboardEventDetails<
  StatusCell_TutorDashboardEventFragment,
  StatusCell_TutorDashboardCohortFragment,
  StatusCell_TutorDashboardEngagementFragment
>;

export function statusCell<D extends EventDetails>(
  setShowSessionReportModal: (value: ShowSessionReportModalProps) => void
): Column<D> {
  return {
    id: "statusCell",
    Header: "Status",
    Cell: ({
      row: {
        original: {
          cohort,
          engagement,
          endDateTime,
          startDateTime,
          cohortSession,
          scheduleStatus,
          minutesElapsed,
          durationMinutes,
          minutesRemaining,
        },
      },
    }: CellProps<D>) => {
      const { isLessThanXlScreen } = useLayout();
      const timeoutRef = useRef<NodeJS.Timeout | null>(null);
      const [isSessionOver, setIsSessionOver] = useState(false);
      const isOngoing = scheduleStatus === EventScheduleStatus.ONGOING;
      const isPast = scheduleStatus === EventScheduleStatus.CONCLUDED;
      const isUpcoming = scheduleStatus === EventScheduleStatus.UPCOMING;

      const sessionEndDateTime = new Date(endDateTime);
      const sessionStartDateTime = new Date(startDateTime);
      const startTimeFormatted = format(sessionStartDateTime, "h:mm a");
      const endTimeFormatted = format(sessionEndDateTime, "h:mm	a");

      useEffect(() => {
        const checkIfSessionOver = () => {
          const now = Date.now();
          const endTime = new Date(endDateTime).getTime();
          if (endTime <= now) return setIsSessionOver(true);
          const timeUntilEnd = endTime - now;
          timeoutRef.current = setTimeout(() => {
            setIsSessionOver(true);
          }, timeUntilEnd + 1);
        };

        checkIfSessionOver();

        return () => {
          timeoutRef.current && clearTimeout(timeoutRef.current);
        };
      }, [endDateTime]);

      const timeUntilStart = intervalToDuration({
        start: new Date(),
        end: startDateTime,
      });

      const formattedTimeUntilStart = formatDuration(timeUntilStart, {
        format: ["hours", "minutes"],
      });

      const getRelativeDayName = isToday(startDateTime)
        ? "Today"
        : isYesterday(startDateTime)
        ? "Yesterday"
        : isTomorrow(startDateTime)
        ? "Tomorrow"
        : format(startDateTime, "EEEE, MM/dd/yyyy");

      const { id, sessionReportId } = cohortSession || {};

      return (
        <div className="flex flex-col justify-between items-center gap-5 lg:flex-row">
          <div className="flex flex-col text-sm font-medium gap-1">
            <div className="relative flex flex-row items-center w-auto gap-3">
              {isOngoing && (
                <div className="absolute top-[4px] left-[-18px] w-3 h-3 rounded-full bg-rose-500 animate-bounce" />
              )}
              <div
                className={clsx(
                  "font-bold",
                  isOngoing && "text-rose-500",
                  isPast && "text-gray-800",
                  isUpcoming && "text-indigo-500"
                )}
              >
                {isOngoing &&
                  `Ongoing, ${minutesElapsed} minutes elapsed; ${minutesRemaining} remaining`}
                {isPast && "This session is now over."}
                {isUpcoming && `Upcoming in ${formattedTimeUntilStart}`}
              </div>
            </div>
            {`${startTimeFormatted} - ${endTimeFormatted} (${durationMinutes} min) ${getRelativeDayName}`}
          </div>
          <div className="flex flex-col justify-center items-end gap-y-1.5 min-w-[120px] -my-1">
            <Tooltip disabled={!isLessThanXlScreen} content="Join Meeting">
              <Link
                href={getHostShortMeetingLink(
                  getOrigin(),
                  cohort?.publicReferenceId ?? "UNKNOWN_COHORT",
                  engagement?.videoProvider
                )}
                target="_blank"
              >
                <div className="flex flex-center h-[28px] w-fit px-[6px] bg-blue-500 rounded-md">
                  <Icon
                    icon="zoom"
                    color="text-white"
                    className={clsx(
                      "w-[16px] h-[16px]",
                      isLessThanXlScreen ? "mx-[3px]" : ""
                    )}
                  />
                  {!isLessThanXlScreen && (
                    <div className="flex h-full items-center text-white text-sm ml-[6px] mr-[6px]">
                      Join Meeting
                    </div>
                  )}
                </div>
              </Link>
            </Tooltip>

            {isSessionOver && (
              <Tooltip
                disabled={!isLessThanXlScreen}
                content="Submit Session Report"
              >
                <Button
                  height="xs"
                  className={clsx(
                    "leading-3 gap-x-2",
                    darkButtonStyles,
                    getSessionButtonStyles(!!sessionReportId)
                  )}
                  onClick={() => {
                    if (id)
                      setShowSessionReportModal({
                        show: true,
                        cohortSessionId: id,
                        sessionReportId: sessionReportId ?? null,
                      });
                  }}
                >
                  <Icon
                    size={4}
                    color="text-white"
                    icon={!!sessionReportId ? "eyeOn" : "submit"}
                    className={clsx(isLessThanXlScreen ? "-mx-[6px]" : "-ml-1")}
                  />
                  {!isLessThanXlScreen ? "Session Report" : null}
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  };
}
