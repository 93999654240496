import { gql } from "@apollo/client";
import {
  HostPresenceCell_SessionAssignmentRowFragment,
  MentorDashAssignmentRole,
} from "@generated/graphql";
import { destructureCacheKey } from "@utils/strings";
import { Badge, HostAttendanceIndicator, Tooltip } from "components/shared";
import { addMinutes } from "date-fns";
import { HOST_PRESENCE_WIDTH } from "../../constants";

HostPresenceCell.fragments = {
  sessionAssignmentRow: gql`
    fragment HostPresenceCell_SessionAssignmentRow on MentorDashSessionAssignmentRow {
      staffRole
      cohortId
      isNoShowDay
    }
  `,
};

type Props = {
  timeslotString: string;
  tracksLiveAttendance: boolean;
  row: HostPresenceCell_SessionAssignmentRowFragment;
};

export function HostPresenceCell({
  timeslotString,
  tracksLiveAttendance,
  row: { staffRole, cohortId, isNoShowDay },
}: Props) {
  if (staffRole === MentorDashAssignmentRole.Is)
    return <div style={{ width: `${HOST_PRESENCE_WIDTH}px` }} />;

  if (!tracksLiveAttendance || !cohortId)
    return (
      <div
        className="flex flex-center"
        style={{ width: `${HOST_PRESENCE_WIDTH}px` }}
      >
        <Badge className="bg-gray-200 text-gray-900 text-center">
          UNAVAILABLE
        </Badge>
      </div>
    );

  const [hours, minutes, duration] = destructureCacheKey(timeslotString);
  const sessionStartTime = new Date();

  sessionStartTime.setHours(0, 0, 0, 0);
  sessionStartTime.setHours(sessionStartTime.getHours() + Number(hours));
  sessionStartTime.setMinutes(sessionStartTime.getMinutes() + Number(minutes));

  const sessionEndTime = addMinutes(sessionStartTime, Number(duration));

  const badgeNoShow = isNoShowDay && (
    <Tooltip content="This cohort has been identified as a no-show day.">
      <Badge className="bg-purple-200 text-purple-900 text-center">
        NO SHOW
      </Badge>
    </Tooltip>
  );

  const attendanceIndicator = tracksLiveAttendance && cohortId && (
    <HostAttendanceIndicator
      cohortId={cohortId}
      cohortSessionStartTime={sessionStartTime}
      cohortSessionEndTime={sessionEndTime}
    />
  );

  return (
    <div
      className="flex flex-center gap-x-2"
      style={{ width: `${HOST_PRESENCE_WIDTH}px` }}
    >
      {badgeNoShow}
      {attendanceIndicator}
    </div>
  );
}
