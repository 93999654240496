import { gql, useMutation } from "@apollo/client";
import {
  AttendanceCell_SessionAssignmentRowFragment,
  AttendanceModalUpdateAttendanceMutation,
  AttendanceModalUpdateAttendanceMutationVariables,
  CohortAssignmentRole,
  CohortSessionTeacherAttendanceStatus,
  EngagementInstructionalSupportAttendanceStatus,
  MentorDashAssignmentRole,
  MentorDashAttendanceStatus,
  TakeIsAttendanceMutation,
  TakeIsAttendanceMutationVariables,
} from "@generated/graphql";
import {
  AttendanceStatusButtonsPanel,
  AttendanceStatusPillButton,
} from "@shared/AttendanceGrades/components";
import clsx from "clsx";
import { AttendanceRobot, Icon, Tooltip } from "components/shared";
import { useCallback, useMemo, useState } from "react";
import { UpdateISAttendanceType, UpdateTTAttendanceType } from "../../types";
import { MentorDashAttendanceNote } from "../MentorDashAttendanceNote";
import { SubstituteSelectorWrapper } from "../SubstituteSelectorWrapper";

AttendanceCell.fragments = {
  sessionAssignmentRow: gql`
    fragment AttendanceCell_SessionAssignmentRow on MentorDashSessionAssignmentRow {
      staffRole
      attendanceId
      substituteId
      substituteName
      attendanceNote
      cohortSessionId
      attendanceStatus
      attendanceAutomaticallyUpdated
    }
  `,
};

const TAKE_TT_ATTENDANCE_MUTATION = gql`
  mutation AttendanceModalUpdateAttendance(
    $input: EditCohortSessionTeacherAttendanceInput!
  ) {
    editCohortSessionTeacherAttendance(input: $input) {
      id
    }
  }
`;

const TAKE_IS_ATTENDANCE_MUTATION = gql`
  mutation TakeISAttendance(
    $input: EditEngagementInstructionalSupportAttendanceInput!
  ) {
    editEngagementInstructionalSupportAttendance(input: $input) {
      id
    }
  }
`;

type Props = {
  colWidth: number;
  row: AttendanceCell_SessionAssignmentRowFragment;
};

export function AttendanceCell({
  colWidth,
  row: {
    staffRole,
    attendanceId,
    substituteId,
    substituteName,
    cohortSessionId,
    attendanceStatus,
    attendanceNote: notes,
    attendanceAutomaticallyUpdated,
  },
}: Props) {
  const isTT = staffRole === MentorDashAssignmentRole.Tt;
  const [showNote, setShowNote] = useState<boolean>(false);
  const { Present, Absent, Unknown } = MentorDashAttendanceStatus;

  const currAttendance = useMemo(() => {
    return { id: String(attendanceId), notes };
  }, [attendanceId, notes]);

  const [takeTTAttendanceMutation, { loading: takeTTAttendanceLoading }] =
    useMutation<
      AttendanceModalUpdateAttendanceMutation,
      AttendanceModalUpdateAttendanceMutationVariables
    >(TAKE_TT_ATTENDANCE_MUTATION);

  const updateTTAttendance = useCallback(
    (update: Partial<UpdateTTAttendanceType>) => {
      takeTTAttendanceMutation({
        variables: {
          input: {
            ...currAttendance,
            substituteId,
            assignmentRole: CohortAssignmentRole.PrimaryTeacher,
            status: (update.status ??
              attendanceStatus) as CohortSessionTeacherAttendanceStatus,
            ...update,
          },
        },
      });
    },
    [attendanceStatus, currAttendance, substituteId, takeTTAttendanceMutation]
  );

  const [takeISAttendanceMutation, { loading: takeISAttendanceLoading }] =
    useMutation<TakeIsAttendanceMutation, TakeIsAttendanceMutationVariables>(
      TAKE_IS_ATTENDANCE_MUTATION
    );

  const updateISAttendance = useCallback(
    (update: Partial<UpdateISAttendanceType>) => {
      takeISAttendanceMutation({
        variables: {
          input: {
            ...currAttendance,
            status: (update.status ??
              attendanceStatus) as EngagementInstructionalSupportAttendanceStatus,
            ...update,
          },
        },
      });
    },
    [attendanceStatus, currAttendance, takeISAttendanceMutation]
  );

  const isLoading = isTT ? takeTTAttendanceLoading : takeISAttendanceLoading;

  return (
    <div
      className={clsx("flex flex-col px-3")}
      style={{ width: `${colWidth}px` }}
    >
      {attendanceId ? (
        <>
          <div
            className={clsx(
              "flex flex-row gap-x-1 items-center",
              showNote && "pt-[3px]"
            )}
          >
            <AttendanceStatusButtonsPanel
              status={attendanceStatus}
              loading={isLoading}
              statusOptions={[Present, Absent]}
              updateStudentAttendance={async (attendStatus) => {
                if (isTT) {
                  const status =
                    attendStatus as unknown as CohortSessionTeacherAttendanceStatus;
                  updateTTAttendance({ status });
                } else {
                  const status =
                    attendStatus as unknown as EngagementInstructionalSupportAttendanceStatus;
                  updateISAttendance({ status });
                }
              }}
            />

            <SubstituteSelectorWrapper
              substituteName={substituteName}
              cohortSessionId={cohortSessionId}
              selectedSubstituteId={substituteId ?? null}
              show={attendanceStatus === Absent && isTT && !!cohortSessionId}
              setSubstituteId={(id) => updateTTAttendance({ substituteId: id })}
            />

            <AttendanceRobot
              className="pl-[6px] pr-[1px] pb-[3px]"
              isAutomaticallyUpdated={!!attendanceAutomaticallyUpdated}
            />

            <Tooltip
              tooltipProps={{ place: "right" }}
              content={
                !!notes
                  ? showNote
                    ? "Collapse Note"
                    : "View Note"
                  : "Leave a note"
              }
            >
              <span
                className="flex relative cursor-pointer group items-center justify-start text-sm"
                onClick={() => setShowNote(!showNote)}
              >
                {!!notes && (
                  <div className="absolute top-[1px] right-[1px] h-[10px] w-[10px] rounded-full bg-blue-600" />
                )}

                <Icon
                  size={8}
                  icon="note"
                  color="text-slate-700 group-hover:text-blue-500"
                />
              </span>
            </Tooltip>
          </div>

          <MentorDashAttendanceNote
            showNote={showNote}
            loading={isLoading}
            colWidth={colWidth}
            attendanceNote={notes}
            className="!mt-[2px] pb-1"
            updateAttendanceNote={(notes: string) =>
              isTT
                ? updateTTAttendance({ notes })
                : updateISAttendance({ notes })
            }
          />
        </>
      ) : (
        <Tooltip
          content="This attendance record has not been generated."
          tooltipProps={{ place: "right" }}
        >
          <AttendanceStatusPillButton
            className="w-fit"
            status={Unknown}
            isSelected={true}
          />
        </Tooltip>
      )}
    </div>
  );
}
