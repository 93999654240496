import {
  MentorDashAssignmentRole,
  MentorDashAttendanceStatus,
  MentorDashboardEngagementTable_SessionAssignmentRowFragment,
} from "@generated/graphql";
import {
  ATTENDANCE_COL_3XL_WIDTH,
  ATTENDANCE_COL_MIN_WIDTH,
  CHAR_WIDTH,
  OTHER_WIDTH,
  PRONOUNS_WIDTH,
  STAFF_CELL_EXTRAS,
  STAFF_COL_MAX_WIDTH,
  STAFF_COL_MIN_WIDTH,
  SUB_DEFAULT_WIDTH,
  SUB_MENU_EXTRAS,
} from "./constants";

export const getColWidths = (
  screenWidth: number,
  isLessThan3XlScreen: boolean,
  rows: MentorDashboardEngagementTable_SessionAssignmentRowFragment[]
) => {
  const { Tt } = MentorDashAssignmentRole;
  const { Absent } = MentorDashAttendanceStatus;

  let substituteWidth = 0;
  let staffColWidth = STAFF_COL_MIN_WIDTH;

  rows.forEach((row) => {
    const { staffRole, substituteName, attendanceStatus } = row;
    // Calculatess Staff Col Width
    const nameWidth = row.staffName.length * CHAR_WIDTH;
    const pronounsWidth = row.staffPronouns ? PRONOUNS_WIDTH : 0;
    const rowStaffWidth = nameWidth + pronounsWidth + STAFF_CELL_EXTRAS;
    staffColWidth = Math.max(rowStaffWidth, STAFF_COL_MIN_WIDTH);
    // Calculates Attendance Col Subsstitute Section Width
    const isShowingSub = staffRole === Tt && attendanceStatus === Absent;
    const hasSubWithName = isShowingSub && !!substituteName;
    const subWidth = hasSubWithName
      ? substituteName.length * CHAR_WIDTH + SUB_MENU_EXTRAS
      : SUB_DEFAULT_WIDTH;
    substituteWidth = Math.max(isShowingSub ? subWidth : 0, 0);
  });

  // CalcsAttendance Col Width | If > 3Xl Screen we attempt a fixed width for consistency
  const minAttendanceWidth = ATTENDANCE_COL_MIN_WIDTH + substituteWidth;
  const attendanceColWidth = isLessThan3XlScreen
    ? minAttendanceWidth
    : Math.max(minAttendanceWidth, ATTENDANCE_COL_3XL_WIDTH);

  const calculatedColsWidth = attendanceColWidth + staffColWidth;
  const unassignedWidth = screenWidth - (OTHER_WIDTH + calculatedColsWidth);
  // Largest potential Staff Col width by adding all (positive) unassigned width
  const maxPossibleStaffWidth = staffColWidth + Math.max(unassignedWidth, 0);

  return {
    attendanceColWidth,
    staffColWidth: Math.min(maxPossibleStaffWidth, STAFF_COL_MAX_WIDTH),
  };
};
