import { gql } from "@apollo/client";
import {
  MentorDashAssignmentRole,
  MentorDashboardEngagementTable_DashboardEngagementFragment,
  MentorDashboardEngagementTable_SessionAssignmentRowFragment,
} from "@generated/graphql";
import { CohortInfoLine } from "@shared/CohortInfoLine";
import {
  SessionReportDetails,
  SessionReportModal,
} from "@shared/SessionReports";
import { getOrigin } from "@utils/browser";
import { getHostShortMeetingLink } from "@utils/roomUrls";
import { Routes } from "@utils/routes";
import { CohortDetailsOpenIcon } from "components/cohorts/CohortDetailsModal/components/CohortDetailsOpenIcon";
import { Icon, IconText, Link, Tooltip } from "components/shared";
import { useState } from "react";

CohortSessionDetailsCell.fragments = {
  sessionAssignmentRow: gql`
    fragment CohortSessionDetailsCell_SessionAssignmentRow on MentorDashSessionAssignmentRow {
      subject
      cohortId
      staffRole
      cohortName
      subSubject
      cohortSessionId
      sessionReportId
      instructionLevel
      cohortMeetingRoom
      cohortPublicReferenceId
      cohortSessionEndDateTime
      hasSessionReportConcerns
      cohortSessionEndDateTime
    }
  `,
};

type Props = {
  timeslotString: string;
  row: MentorDashboardEngagementTable_SessionAssignmentRowFragment;
  engagement: MentorDashboardEngagementTable_DashboardEngagementFragment;
};

export function CohortSessionDetailsCell({
  engagement: { engagementId, videoProvider },
  timeslotString,
  row: {
    subject,
    cohortId,
    staffRole,
    cohortName,
    subSubject,
    cohortSessionId,
    sessionReportId,
    instructionLevel,
    cohortMeetingRoom,
    cohortPublicReferenceId,
    hasSessionReportConcerns,
    cohortSessionEndDateTime,
  },
}: Props) {
  const hasSessionReport = !!cohortSessionId && !!sessionReportId;
  const [showReportModal, setShowReportModal] = useState(false);

  return staffRole === MentorDashAssignmentRole.Tt ? (
    <>
      <div className="flex flex-1 items-center justify-start gap-2 px-3">
        <Tooltip
          content="View Attendance on Engagements Dashboard"
          tooltipProps={{ delayShow: 500, place: "right" }}
        >
          <IconText
            link
            className="mr-[10px]"
            icon="cohortFill"
            child={
              <Link
                customColor
                route={Routes.engagementsDashboard}
                routeProps={[
                  `engagementId=${engagementId}&timeslot=${timeslotString}`,
                ]}
              >
                {cohortName}
              </Link>
            }
          />
        </Tooltip>

        <CohortInfoLine
          instructionLevel={instructionLevel ?? undefined}
          subjectCombos={subject ? [{ subject, subSubject }] : []}
        />

        <CohortDetailsOpenIcon cohortId={cohortId ?? "0"} />

        <SessionReportDetails
          canCreateReport={false}
          hasSessionReport={hasSessionReport}
          hasConcerns={hasSessionReportConcerns}
          sessionEndDateTime={new Date(cohortSessionEndDateTime).getTime()}
          onViewSessionReport={() => setShowReportModal(hasSessionReport)}
        />

        {cohortMeetingRoom && cohortPublicReferenceId && videoProvider && (
          <Tooltip content="Join Meeting" tooltipProps={{ place: "right" }}>
            <Link
              target="_blank"
              href={getHostShortMeetingLink(
                getOrigin(),
                cohortPublicReferenceId,
                videoProvider
              )}
            >
              <div className="flex flex-center h-[21px] w-fit px-[6px] bg-blue-500 rounded-md">
                <Icon
                  icon="zoom"
                  color="text-white"
                  className="w-[16px] h-[16px]"
                />
              </div>
            </Link>
          </Tooltip>
        )}
      </div>

      {hasSessionReport && (
        <SessionReportModal
          readOnly
          show={showReportModal}
          sessionReportId={sessionReportId}
          cohortSessionId={cohortSessionId}
          onClose={() => setShowReportModal(false)}
        />
      )}
    </>
  ) : (
    <div className="flex flex-1 items-center justify-start px-3">
      <Link
        customColor
        route={Routes.engagementsDashboard}
        routeProps={[
          `engagementId=${engagementId}&isSubstitute=true&timeslot=${timeslotString}`,
        ]}
      >
        <Tooltip
          tooltipProps={{ place: "right" }}
          content="View Engagement Shift Details"
        >
          <Icon icon="timeLapse" size={5} />
        </Tooltip>
      </Link>
    </div>
  );
}
