import { gql } from "@apollo/client";
import { useAdminDashboardContext } from "@contexts/AdminDashboardProvider";
import {
  AdminDashboardOrgFavoritesFilterModalBody_OrganizationFragment,
  Organization,
} from "@generated/graphql";
import { BulkSelect, Modal } from "@shared";
import { useMemo, useRef, useState } from "react";

AdminDashboardOrgFavoritesFilterModalBody.fragments = {
  organization: gql`
    fragment AdminDashboardOrgFavoritesFilterModalBody_Organization on Organization {
      id
      ...BulkSelect_Node
    }
    ${BulkSelect.fragments.node}
  `,
};

type Props = {
  organizations: AdminDashboardOrgFavoritesFilterModalBody_OrganizationFragment[];
  closeModal(): void;
};

export function AdminDashboardOrgFavoritesFilterModalBody({
  organizations,
  closeModal,
}: Props) {
  const cancelButtonRef = useRef(null);
  const { favoriteOrgs, updateFavoriteOrgs } = useAdminDashboardContext();
  const [selectedOrgIds, setSelectedOrgIds] = useState<Organization["id"][]>(
    favoriteOrgs.map(({ id }) => id)
  );

  const [deselectedOrgs, selectedOrgs] = useMemo(() => {
    const deselectedOrgs: typeof organizations = [];
    const selectedOrgs: typeof organizations = [];
    organizations.forEach((org) => {
      if (selectedOrgIds.includes(org.id)) {
        selectedOrgs.push(org);
      } else {
        deselectedOrgs.push(org);
      }
    });
    return [deselectedOrgs, selectedOrgs];
  }, [organizations, selectedOrgIds]);

  const onSave = () => {
    updateFavoriteOrgs(selectedOrgs.map(({ id, name }) => ({ id, name })));
    closeModal();
  };

  return (
    <div className="flex flex-col gap-y-2 pt-3">
      <BulkSelect
        itemName="Organization"
        selectedItems={selectedOrgs}
        deselectedItems={deselectedOrgs}
        setSelectedItemIds={setSelectedOrgIds}
        binClassName="h-96"
      />

      <Modal.Buttons>
        <Modal.Button type="confirm" onClick={onSave}>
          Save
        </Modal.Button>
        <Modal.Button type="cancel" onClick={closeModal} ref={cancelButtonRef}>
          Cancel
        </Modal.Button>
      </Modal.Buttons>
    </div>
  );
}
