import { useAdminDashboardContext } from "@contexts/AdminDashboardProvider";
import { useLayout } from "@contexts/LayoutProvider";
import { breadcrumbs } from "@utils/breadcrumbs";
import { clsx } from "clsx";
import { Page, PageHeader } from "components/shared";
import { useEffect, useState } from "react";
import {
  AdminDashboardCalendar,
  AdminDashboardNavigationCalendars,
  AdminDashboardOverview,
  AdminDashboardViewModeTransitionContainer,
} from "./components";
import {
  AdminDashboardOrgFavoritesFilterModal,
  AdminDashboardOrgFavoritesFilterTool,
} from "./components/AdminDashboardOrgFavoritesFilter";
import { AdminDashboardViewMode } from "./types";

export const AdminDashboard = () => {
  const { viewMode } = useAdminDashboardContext();
  const { screenWidth } = useLayout();

  const isCalendarView = viewMode === AdminDashboardViewMode.Calendar;
  const [calendarIsOpen, setCalendarIsOpen] = useState<boolean>(false);
  const [showFavoriteOrgsModal, setShowFavoriteOrgsModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (viewMode === AdminDashboardViewMode.Calendar) setCalendarIsOpen(true);
  }, [viewMode]);

  const isMinScreen = screenWidth < 1000;

  return (
    <Page
      className={clsx(
        "relative gap-y-4 !py-0",
        !isCalendarView && !calendarIsOpen && !isMinScreen && "!mt-[64px]",
        !isCalendarView && !calendarIsOpen && isMinScreen && "!mt-[136px]",
        !isCalendarView && calendarIsOpen && "!mt-[338px]",
        isCalendarView && !calendarIsOpen && !isMinScreen && "!mt-[72px]",
        isCalendarView && !calendarIsOpen && isMinScreen && "!mt-[136px]",
        isCalendarView && calendarIsOpen && "!mt-[348px]"
      )}
    >
      <PageHeader breadcrumbs={[breadcrumbs.home(), breadcrumbs.dashboard()]} />

      <AdminDashboardNavigationCalendars
        calendarIsOpen={calendarIsOpen}
        isMinimizedScreen={isMinScreen}
        setCalendarIsOpen={setCalendarIsOpen}
      />

      <AdminDashboardViewModeTransitionContainer
        className="z-[0] flex flex-col gap-y-1"
        show={isCalendarView}
      >
        <AdminDashboardOrgFavoritesFilterTool
          onClickEdit={() => setShowFavoriteOrgsModal(true)}
        />
        <div className="bg-white pl-2 pr-1 rounded-lg">
          <AdminDashboardCalendar
            className={clsx(
              !calendarIsOpen && !isMinScreen && "h-[calc(100vh-238px)]",
              !calendarIsOpen && isMinScreen && "h-[calc(100vh-301px)]",
              calendarIsOpen && !isMinScreen && "h-[calc(100vh-516px)]"
            )}
          />
        </div>
      </AdminDashboardViewModeTransitionContainer>

      <AdminDashboardViewModeTransitionContainer show={!isCalendarView}>
        <AdminDashboardOverview />
      </AdminDashboardViewModeTransitionContainer>

      <AdminDashboardOrgFavoritesFilterModal
        show={showFavoriteOrgsModal}
        closeModal={() => setShowFavoriteOrgsModal(false)}
      />
    </Page>
  );
};
