import { SpecialDayType } from "@generated/graphql";
import { IconType } from "components/shared/Icon";

export const eventToColourMap: Record<SpecialDayType, string> = {
  [SpecialDayType.NoShowDay]: "text-green-700",
  [SpecialDayType.AssessmentDay]: "text-sky-600",
  [SpecialDayType.CancellationDay]: "text-red-500",
};

export const eventToIconMap: Record<SpecialDayType, IconType> = {
  [SpecialDayType.NoShowDay]: "noShow",
  [SpecialDayType.AssessmentDay]: "assessment",
  [SpecialDayType.CancellationDay]: "cancelCalendar",
};

export const cohortEventMessageMap: Record<SpecialDayType, string> = {
  [SpecialDayType.NoShowDay]:
    "These Cohorts take place on a no-show day on this day.",
  [SpecialDayType.AssessmentDay]:
    "These Cohorts take place on an assessment day on this day.",
  [SpecialDayType.CancellationDay]:
    "These Cohorts have had sessions cancelled on this day.",
};

export const engagementEventMessageMap: Record<SpecialDayType, string> = {
  [SpecialDayType.NoShowDay]: "This day is a no-show day for all Cohorts.",
  [SpecialDayType.AssessmentDay]:
    "This day is an assessment day for all Cohorts.",
  [SpecialDayType.CancellationDay]: "N/A",
};

export const getHolidayIcon = (isUSHoliday: boolean): IconType =>
  isUSHoliday ? "palmTree" : "calendarPause";

export const getHolidayColor = (isUSHoliday: boolean): string =>
  isUSHoliday ? "text-orange-700" : "text-green-700";

export const getHolidayTooltipContent = (
  isUSHoliday: boolean,
  count: number
): string => {
  // "dem" is short for "demonstrative".
  const [dem, s] = count === 1 ? ["this", ""] : ["these", "s"];
  return isUSHoliday
    ? `Every session will be suspended on this day to observe ${dem} holiday${s}.`
    : `Sessions within this Organization will be suspended on this day to observe ${dem} No Tutoring day${s}.`;
};
