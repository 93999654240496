// For Static° Col Widths | °Details is flex-1 with a min-width
export const ROLE_WIDTH = 70;
export const DETAILS_MIN_WIDTH = 350;
export const HOST_PRESENCE_WIDTH = 160;

// For Calculated Col Widths | [Staff Name && Attendance Cols]
export const CHAR_WIDTH = 8;
export const PRONOUNS_WIDTH = 58;
export const SUB_MENU_EXTRAS = 35;
export const STAFF_CELL_EXTRAS = 80;
export const SUB_DEFAULT_WIDTH = 175;
export const STAFF_COL_MIN_WIDTH = 260;
export const STAFF_COL_MAX_WIDTH = 360;
export const ATTENDANCE_COL_MIN_WIDTH = 260;
export const ATTENDANCE_COL_3XL_WIDTH = 450;
// Sidebar + Padding + Other Cells
export const OTHER_WIDTH =
  240 + 16 * 5 + (ROLE_WIDTH + HOST_PRESENCE_WIDTH + DETAILS_MIN_WIDTH);
