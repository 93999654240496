import { AccountRole, GetCurrentUserQuery } from "@generated/graphql";
import { breadcrumbs } from "@utils/breadcrumbs";
import { Routes } from "@utils/routes";
import { Page, PageHeader } from "components/shared";
import { AttendanceDataProvider } from "contexts/AttendanceDataProvider";
import { MentorDashboard } from "./MentorDashboard";
import { TutorDashboard } from "./TutorDashboard/TutorDashboard";

type Props = { currentUser: NonNullable<GetCurrentUserQuery["currentUser"]> };

export const UserDashboard = ({ currentUser }: Props) => (
  <Page className="gap-y-4 mb-32">
    <PageHeader
      breadcrumbs={[
        breadcrumbs.home(),
        { name: "Dashboard", route: Routes.home, current: true },
      ]}
    />

    {currentUser.accountRole === AccountRole.MentorTeacher ? (
      <AttendanceDataProvider>
        <MentorDashboard currentUser={currentUser} />
      </AttendanceDataProvider>
    ) : (
      <TutorDashboard currentUser={currentUser} />
    )}
  </Page>
);
