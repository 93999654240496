import {
  MentorDashboardEngagementTable_DashboardEngagementFragment,
  MentorDashboardEngagementTable_SessionAssignmentRowFragment,
} from "@generated/graphql";
import clsx from "clsx";
import {
  AttendanceCell,
  CohortSessionDetailsCell,
  HostPresenceCell,
  RoleCell,
  StaffCell,
} from "./cells";

type Props = {
  zIndex: number;
  className?: string;
  staffColWidth: number;
  timeslotString: string;
  attendanceColWidth: number;
  row: MentorDashboardEngagementTable_SessionAssignmentRowFragment;
  engagement: MentorDashboardEngagementTable_DashboardEngagementFragment;
};

export const StaffAttendanceTableRow = ({
  row,
  zIndex,
  className,
  engagement,
  staffColWidth,
  timeslotString,
  attendanceColWidth,
}: Props) => (
  <div
    style={{ zIndex }}
    className={clsx(
      "flex items-center w-full",
      !row.staffUserId && "bg-rose-100",
      className
    )}
  >
    <StaffCell
      row={row}
      colWidth={staffColWidth}
      engagementId={engagement.engagementId}
    />

    <RoleCell staffRole={row.staffRole} />

    <HostPresenceCell
      row={row}
      timeslotString={timeslotString}
      tracksLiveAttendance={engagement.tracksLiveAttendance}
    />

    <AttendanceCell colWidth={attendanceColWidth} row={row} />

    <CohortSessionDetailsCell
      row={row}
      engagement={engagement}
      timeslotString={timeslotString}
    />
  </div>
);
